.pagination {
  margin-top: 45px;
  margin-bottom: 35px;
  /* display: flex; */
  /* list-style: none; */
  text-decoration: none;
  /* outline: none; */
}

.pagination > .active > a {
  background-color: #b78b4c;
  color: #fff;
  text-decoration: none;
}

.pagination > li > a {
  border: 1px solid #b78b4c;
  padding: 5px 10px;
  /* outline: none; */
  cursor: pointer;
  text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #b78b4c;
  background-color: rgb(255, 255, 255);
}

.border-zk {
  border: 1px solid #c3c3c3;
}

.stepper .active {
  background-color: #b78b4c !important;
  color: #fff !important;
}

.stepper .completed {
  background-color: #b78b4c !important;
  color: #fff !important;
}

.text-hover-theme:hover {
  color: #b78b4c !important;
}

.btn-theme {
  background-color: #b78b4c !important;
  color: #fff !important;
}

.btn-secondary-theme {
  background-color: rgba(183, 139, 76, 0.1) !important;
  color: #b78b4c !important;
}

.btn-secondary-theme:hover {
  background-color: #b78b4c !important;
  color: #fff !important;
}

.btn-silent-theme {
  background-color: #f5f8fa !important;
  color: #7e8299 !important;
}

.btn-silent-theme:hover {
  background-color: rgba(183, 139, 76, 0.1) !important;
  color: #b78b4c !important;
}

.btn-icon-theme {
  color: #b78b4c !important;
}

.text-theme {
  color: #b78b4c !important;
}

.leadUnread {
  background-color: #f2f2f2 !important;
}

.leadUnreadDark {
  background-color: #3232488c !important;
}
