//
// Menu Theme
//

// Theme text colors
@each $name, $color in $theme-text-colors {
    .menu-#{$name} {
        .menu-item {
            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state( var(--kt-#{$name}), var(--kt-#{$name}), var(--kt-#{$name}), var(--kt-#{$name}), null );
        }
    }

    .menu-title-#{$name} {
        .menu-item {
            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state( var(--kt-text-#{$name}), null, null, null, null );
        }
    }

    .menu-icon-#{$name} {
        .menu-item {
            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state( null, var(--kt-text-#{$name}), null, null, null );
        }
    }

    .menu-bullet-#{$name} {
        .menu-item {
            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state( null, null, var(--kt-text-#{$name}), null, null );
        }
    }

    .menu-arrow-#{$name} {
        .menu-item {
            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
            @include menu-link-default-state( null, null, null, var(--kt-text-#{$name}), null );
        }
    }
}

// Default background states
.menu-hover-bg {
    .menu-item {
        @include menu-link-hover-state( 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-bg-color-hover) 
        );
    }
}

.menu-here-bg {
    .menu-item {
        @include menu-link-here-state( 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-bg-color-here) 
        );
    }
}

.menu-root-here-bg {
    > .menu-item {
        @include menu-link-here-state( 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-bg-color-here) 
        );
    }
}

// Desktop mode
@include media-breakpoint-up(lg) {
    .menu-root-here-bg-desktop {
        > .menu-item {
            @include menu-link-here-state( 
                var(--kt-menu-link-color-here), 
                var(--kt-menu-link-color-here), 
                var(--kt-menu-link-color-here), 
                var(--kt-menu-link-color-here), 
                var(--kt-menu-link-bg-color-here) 
            );
        }
    }
}

.menu-show-bg {
    .menu-item {
        @include menu-link-show-state( 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-bg-color-show) 
        );
    }
}

.menu-active-bg {
    .menu-item {
        @include menu-link-active-state( 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-bg-color-active) 
        );
    }
}

.menu-state-bg {
    .menu-item {
        @include menu-link-hover-state( 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-bg-color-hover) 
        );

        @include menu-link-here-state( 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-bg-color-here) 
        );

        @include menu-link-show-state( 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-bg-color-show) 
        );

        @include menu-link-active-state( 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-bg-color-active) 
        );
    }
}

.menu-state-color {
    .menu-item {
        @include menu-link-hover-state( 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            var(--kt-menu-link-color-hover), 
            null
        );

        @include menu-link-here-state( 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            var(--kt-menu-link-color-here), 
            null
        );

        @include menu-link-show-state( 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            var(--kt-menu-link-color-show), 
            null
        );

        @include menu-link-active-state( 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            var(--kt-menu-link-color-active), 
            null
        );
    }
}

// Primary background states
.menu-hover-bg-primary {
    .menu-item {
        @include menu-link-hover-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary) 
        );
    }
}

.menu-show-bg-primary {
    .menu-item {
        @include menu-link-show-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary) 
        );
    }
}

.menu-here-bg-primary {
    .menu-item {
        @include menu-link-here-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary)
        );
    }
}

.menu-active-bg-primary {
    .menu-item {
        @include menu-link-active-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary)
        );
    }
}

.menu-state-bg-primary {
    .menu-item {
        @include menu-link-hover-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary) 
        );

        @include menu-link-show-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary) 
        );

        @include menu-link-here-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary)
        );

        @include menu-link-active-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary)
        );

        @include menu-link-show-state( 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary-inverse), 
            var(--kt-primary) 
        );
    }
}

// Light primary background states
.menu-show-bg-light-primary {
    .menu-item {
        @include menu-link-show-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
    }
}

.menu-here-bg-light-primary {
    .menu-item {
        @include menu-link-here-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
    }
}

.menu-hover-bg-light-primary {
    .menu-item {
        @include menu-link-hover-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
    }
}

.menu-active-bg-light-primary {
    .menu-item {
        @include menu-link-active-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
    }
}

.menu-state-bg-light-primary {
    .menu-item {
        @include menu-link-show-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
        @include menu-link-here-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
        @include menu-link-hover-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
        @include menu-link-active-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary-light) );
    }
}

// Primary color states
.menu-hover-primary {
    .menu-item {
        @include menu-link-hover-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
    }
}

.menu-show-primary {
    .menu-item {
        @include menu-link-show-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
    }
}

.menu-here-primary {
    .menu-item {
        @include menu-link-here-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
    }
}

.menu-active-primary {
    .menu-item {
        @include menu-link-active-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
    }
}

.menu-state-primary {
    .menu-item {
        @include menu-link-hover-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
        @include menu-link-show-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
        @include menu-link-here-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
        @include menu-link-active-state( var(--kt-primary), var(--kt-primary), var(--kt-primary), var(--kt-primary), null );
    }
}

.menu-state-dark {
    .menu-item {
        @include menu-link-hover-state( var(--kt-dark), var(--kt-dark), var(--kt-dark), var(--kt-dark), null );
        @include menu-link-show-state( var(--kt-dark), var(--kt-dark), var(--kt-dark), var(--kt-dark), null );
        @include menu-link-here-state( var(--kt-dark), var(--kt-dark), var(--kt-dark), var(--kt-dark), null );
        @include menu-link-active-state( var(--kt-dark), var(--kt-dark), var(--kt-dark), var(--kt-dark), null );
    }
}

// Primary title color states
.menu-hover-title-primary {
    .menu-item {
        @include menu-link-hover-state( var(--kt-primary), null, null, null, null );
    }
}

.menu-here-title-primary {
    .menu-item {
        @include menu-link-here-state( var(--kt-primary), null, null, null, null );
    }
}

.menu-show-title-primary {
    .menu-item {
        @include menu-link-show-state( var(--kt-primary), null, null, null, null );
    }
}

.menu-active-title-primary {
    .menu-item {
        @include menu-link-active-state( var(--kt-primary), null, null, null, null );
    }
}

.menu-state-title-primary {
    .menu-item {
        @include menu-link-hover-state( var(--kt-primary), null, null, null, null );
        @include menu-link-here-state( var(--kt-primary), null, null, null, null );
        @include menu-link-show-state( var(--kt-primary), null, null, null, null );
        @include menu-link-active-state( var(--kt-primary), null, null, null, null );
    }
}

// Primary icon color states
.menu-hover-icon-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-hover-state( null, var(blue), null, null, null );
    }
}

.menu-here-icon-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-here-state( null, var(--kt-primary), null, null, null );
    }
}

.menu-show-icon-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-show-state( null, var(--kt-primary), null, null, null );
    }
}

.menu-active-icon-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-active-state( null, var(--kt-primary), null, null, null );
    }
}

.menu-state-icon-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-hover-state( null, var(--kt-primary), null, null, null );
        @include menu-link-here-state( null, var(--kt-primary), null, null, null );
        @include menu-link-show-state( null, var(--kt-primary), null, null, null );
        @include menu-link-active-state( null, var(--kt-primary), null, null, null );
    }
}

// Primary bullet color states
.menu-hover-bullet-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-hover-state( null, null, var(--kt-primary), null, null );
    }
}

.menu-show-bullet-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-show-state( null, null, var(--kt-primary), null, null );
    }
}

.menu-here-bullet-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-here-state( null, null, var(--kt-primary), null, null );
    }
}

.menu-active-bullet-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-active-state( null, null, var(--kt-primary), null, null );
    }
}

.menu-state-bullet-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-hover-state( null, null, var(--kt-primary), null, null );
        @include menu-link-here-state( null, null, var(--kt-primary), null, null );
        @include menu-link-show-state( null, null, var(--kt-primary), null, null );
        @include menu-link-active-state( null, null, var(--kt-primary), null, null );
    }
}

// Primary arrow color states
.menu-hover-arrow-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-hover-state( null, null, null, var(--kt-primary), null );
    }
}

.menu-here-arrow-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-here-state( null, null, null, var(--kt-primary), null );
    }
}

.menu-show-arrow-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-show-state( null, null, null, var(--kt-primary), null );
    }
}

.menu-active-arrow-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-active-state( null, null, null, var(--kt-primary), null );
    }
}

.menu-state-arrow-primary {
    .menu-item {
        //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
        @include menu-link-hover-state( null, null, null, var(--kt-primary), null );
        @include menu-link-here-state( null, null, null, var(--kt-primary), null );
        @include menu-link-show-state( null, null, null, var(--kt-primary), null );
        @include menu-link-active-state( null, null, null, var(--kt-primary), null );
    }
}
