//
// Scrolltop
//

.scrolltop {    
    position: fixed;
    display: none;
    cursor: pointer;
    z-index: $scrolltop-z-index;
    justify-content: center;
    align-items: center;
    width: $scrolltop-size;
    height: $scrolltop-size;
    bottom: $scrolltop-bottom;
    right: $scrolltop-end;    
    background-color: var(--kt-scrolltop-bg-color);
    box-shadow: var(--kt-scrolltop-box-shadow);
    opacity: $scrolltop-opacity;
    transition: $transition-link;
    @include border-radius($border-radius);

    .svg-icon {
        @include svg-icon-size(24px);
        color: var(--kt-scrolltop-icon-color);
    }

    > i {
        font-size: 1.3rem;
        color: var(--kt-scrolltop-icon-color);
    }

    &:hover {
        background-color: var(--kt-scrolltop-bg-color-hover);

        .svg-icon,
        i {
            color: var(--kt-scrolltop-icon-color-hover);
        }
    }

    [data-kt-scrolltop="on"] & {
        opacity: var(--kt-scrolltop-opacity-on);
        animation: animation-scrolltop .4s ease-out 1;
        display: flex;

        &:hover {
            transition: $transition-link;
            opacity: var(--kt-scrolltop-opacity-hover);
        }
    }

    
}

// Tablet & Mobile Modess
@include media-breakpoint-down(lg) {
    .scrolltop { 
        bottom: $scrolltop-bottom-mobile;
        right: $scrolltop-end-mobile;    
        width: $scrolltop-size-mobile;
        height: $scrolltop-size-mobile;
    }
}

// Animations
@keyframes animation-scrolltop {
    from   { margin-bottom: -15px; }
    to {  margin-bottom: 0; }
}
